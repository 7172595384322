<template>
  <table border="solid" cellpadding="10">
    <thead>
      <tr>
        <th rowspan="2">Week</th>
        <th rowspan="2">Inv. on hand</th>
		<th rowspan="2">Price</th>
        <th colspan="3">Sales</th>
        <th rowspan="2">Std Err</th>
        <th colspan="2">Revenue</th>
      </tr>

      <tr>
        <th>Now</th>
        <th>Average</th>
        <th>Projection</th>
        <th>Now</th>
        <th>Projection</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in items" v-bind:key="item">
        <td>
          {{ item.week }}
        </td>
		<td>
          {{ item.onHand }}
        </td>
		<td>
          {{ item.price }}
        </td>
		<td>
          {{ item.sales.now }}
        </td>
		<td>
          {{ item.sales.average }}
        </td>
		<td>
          ${{ item.sales.projection }}
        </td>
		<td>
          {{ item.stdErr }}
        </td>
		<td>
          ${{ item.revenue.now }}
        </td>
		<td>
          ${{ item.revenue.projection }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    items: Array,
  },
};
</script>