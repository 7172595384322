<template>
  <results-table :items="weeks"></results-table>
  <button @click="calculateNextWeek(0)">Same</button>
  <button @click="calculateNextWeek(0.1)">-10%</button>
  <button @click="calculateNextWeek(0.2)">-20%</button>
  <button @click="calculateNextWeek(0.4)">-40%</button>
  <div style="width: 400px">
    <canvas id="myChart" width="400" height="400" style="width: 400px"></canvas>
  </div>
</template>

<script>
import * as Chart from "chart.js";
import { onMounted } from "vue";
import ResultsTable from "./ResultsTable.vue";

const startAmount = 2000;
const startPrice = 60;

const averageDemand = 70;
const sigma = 10;

const maxWeeks = 16;

function randNormal() {
  let u = 0,
    v = 0;
  while (u === 0) u = Math.random(); //Converting [0,1) to (0,1)
  while (v === 0) v = Math.random();
  let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
  num = num / 10.0 + 0.5; // Translate to 0 -> 1
  if (num > 1 || num < 0) return randNormal(); // resample between 0 and 1
  return num;
}

function calculateFirstWeek() {
  const sales = averageDemand - sigma * 3 + Math.round(sigma * 3 * 2 * randNormal());
  return {
    week: 1,
    onHand: startAmount,
    price: startPrice,
    sales: {
      now: sales,
      average: sales,
      projection: sales * startPrice,
    },
    stdErr: 0,
    revenue: {
      now: sales * startPrice,
      projection: sales * startPrice * maxWeeks,
    },
  };
}

function calculateWeek(weeks, index, discount) {
  const previousWeek = weeks[index - 1];

  const sales =
    averageDemand - sigma * 3 + Math.round(sigma * 3 * 2 * randNormal());

  const newPrice = Math.round(previousWeek.price * (1 - discount));

  const week = {
    week: index + 1,
    onHand: previousWeek.onHand - previousWeek.sales.now,
    price: newPrice,
    sales: {
      now: sales,
      average: Math.round((startAmount - (previousWeek.onHand - previousWeek.sales.now - sales)) / (index + 1)),
      projection: newPrice * sales,
    },
    stdErr: 0,
    revenue: {
      now: newPrice * sales,
      projection: sales * newPrice * (maxWeeks - index - 1),
    },
  };

  return [...weeks, week];
}

function extractData(weeks) {
  return weeks.map((week, index) => {
    return {
      x: index + 1,
      y: week.onHand
    };
  });
}

function generateChart(weeks) {
  var ctx = document.getElementById("myChart").getContext("2d");

  const labelsWeek = [];
  for (let i = 1; i <= 16; i++) {
    labelsWeek.push(i);
  }

  const data = {
    labels: labelsWeek,
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "ff00ff",
        borderColor: "00ff00",
        data: extractData(weeks),
        fill: false,
      },
    ],
  };

  new Chart(ctx, {
    type: "line",
    data: data,
    // options: options,
  });
}

const weeks = [calculateFirstWeek()];

export default {
  components: { ResultsTable },
  name: "Graphic",

  data() {
    return {
      weeks,
      currentWeek: 0,
    };
  },

  methods: {
    calculateNextWeek: function (discount) {
      this.currentWeek++;
      this.weeks = calculateWeek(this.weeks, this.currentWeek, discount);
      generateChart(this.weeks);
    },
  },

  setup() {
    onMounted(() => {
      generateChart(weeks);
    });
  },
};
</script>